
import React from "react"

import OnsorderPage from "../../components/negosyonow/controls/onsordereditor";

const DeliveryOrderPage = ({location}) => {
	return <OnsorderPage
				location={location}
				methodid={2}
			/>
}


export default DeliveryOrderPage;
